import React, { useState, useEffect } from "react";

import FancyBackground from "./FancyBackground";
import CenterCard from "./CenterCard";

import "./App.css";

function App() {
  const [recruitmentResources, setRecruitmentResources] = useState(null);

  useEffect(() => {
    if (recruitmentResources) return;
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");
    if (!token) return;
    // Get POST response of whenfin-api.l-uca.com/recruitment/token sending {token: token} as a body
    const attempt_fetch = async () => {
      const whenfinResponse = await fetch(
        "https://whenfin-api.l-uca.com/recruitment/check_token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        }
      );
      // Return if response is not 200
      if (whenfinResponse.status !== 200) return;
      const json = await whenfinResponse.json();
      if (!json.headshot) return;
      setRecruitmentResources({
        headshot: json.headshot,
        sample: json.sample,
      });
    };
    attempt_fetch();
  }, []);

  return (
    <div className="w-full h-full isolate overflow-hidden bg-gray-900 flex items-center justify-center relative sm:p-12 p-8 overscroll-contain">
      <FancyBackground />
      <CenterCard recruitmentResources={recruitmentResources} />
    </div>
  );
}

export default App;
