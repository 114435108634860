import { ArrowRightIcon } from "@heroicons/react/20/solid";
import me from "./me.jpeg";

export default function Content({ currentTab, recruitmentResources }) {
  if (currentTab == "Home")
    return (
      <div className="flex-grow px-4 py-5 sm:p-6 overflow-y-scroll flex flex-row">
        <div className="flex-grow">
          {recruitmentResources ? (
            <h1 className="text-gray-700 text-2xl font-bold p-2 items-center">
              Digital Portfolio{" "}
              {recruitmentResources && (
                <a href="https://www.linkedin.com/in/luca-dovichi-3aa5a2299/">
                  <i className="fa fa-linkedin-square text-2xl text-blue-600 pl-2" />
                </a>
              )}
            </h1>
          ) : (
            <h1 className="text-gray-700 text-2xl font-bold p-2">
              Hi there, I'm Luca :)
            </h1>
          )}
          <h2 className="text-gray-500 text-xl font-bold p-2">
            {recruitmentResources
              ? "My name is Luca Dovichi, and I am a second year at the University of Chicago majoring in Computer Science. "
              : "I'm a second year at the University of Chicago majoring in Computer Science. "}
            I have worked as a research assistant for Prof. Blase Ur's{" "}
            <a href="https://super.cs.uchicago.edu/" className="text-blue-600">
              SUPERgroup
            </a>{" "}
            since May 2021 with a focus on security and equity in machine
            learning.
          </h2>
          <img
            src={recruitmentResources ? recruitmentResources.headshot : me}
            className="w-auto mx-auto sm:w-0 h-48 py-2 rounded-[2rem]"
          />
        </div>
        <img
          src={recruitmentResources ? recruitmentResources.headshot : me}
          className="sm:inline-block hidden sm:w-auto h-64 p-8 rounded-[4rem]"
        />
      </div>
    );
  if (currentTab == "Contact")
    return (
      <div className="flex-grow px-4 py-5 sm:p-6 overflow-y-scroll">
        <h1 className="text-gray-700 text-2xl font-bold p-2">Say hi!</h1>
        <h2 className="text-gray-500 text-xl font-bold p-2">
          I'd love to collaborate on projects or hear any feedback you have. My
          email is lucadovichi (at) gmail... :)
        </h2>
        <div className="flex flex-wrap gap-4 text-lg pt-2">
          <a
            href="https://www.linkedin.com/in/luca-dovichi-3aa5a2299/"
            target="_blank"
          >
            <div className="inline-flex gap-2 p-3 bg-[#0077B5] text-white font-semibold items-center hover:ring ring-[#0077B5]  transition shadow border-gray-300 border rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
              LinkedIn
            </div>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100091700118533"
            target="_blank"
          >
            <div className="inline-flex gap-2 p-3 bg-blue-800 text-white font-semibold items-center hover:ring ring-indigo-800  transition shadow border-gray-300 border rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
              Facebook
            </div>
          </a>
          <a href="https://github.com/Luca-W-D" target="_blank">
            <div className="inline-flex gap-2 p-3 bg-slate-800 text-white font-semibold items-center hover:ring ring-slate-800  transition shadow border-gray-300 border rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
              </svg>
              Github
            </div>
          </a>
          <a href="https://open.spotify.com/user/lucadovichi" target="_blank">
            <div className="inline-flex gap-2 p-3 bg-green-600 text-white font-semibold items-center hover:ring ring-green-600  transition shadow border-gray-300 border rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-spotify"
                viewBox="0 0 16 16"
              >
                {" "}
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.669 11.538a.498.498 0 0 1-.686.165c-1.879-1.147-4.243-1.407-7.028-.77a.499.499 0 0 1-.222-.973c3.048-.696 5.662-.397 7.77.892a.5.5 0 0 1 .166.686zm.979-2.178a.624.624 0 0 1-.858.205c-2.15-1.321-5.428-1.704-7.972-.932a.625.625 0 0 1-.362-1.194c2.905-.881 6.517-.454 8.986 1.063a.624.624 0 0 1 .206.858zm.084-2.268C10.154 5.56 5.9 5.419 3.438 6.166a.748.748 0 1 1-.434-1.432c2.825-.857 7.523-.692 10.492 1.07a.747.747 0 1 1-.764 1.288z" />{" "}
              </svg>
              Spotify
            </div>
          </a>
        </div>
      </div>
    );

  if (currentTab == "Publications")
    return (
      <div className="flex-grow px-4 py-5 sm:p-6 overflow-y-scroll">
        <h1 className="text-gray-700 text-2xl font-bold p-2">Publications</h1>
        <div className="pb-2">
          <a
            href="https://dl.acm.org/doi/full/10.1145/3613904.3642755"
            target="_blank"
          >
            <h1 className="text-gray-700 text-xl font-bold p-2 pb-0">
              JupyterLab in Retrograde: Contextual Notifications That Highlight
              Fairness and Bias Issues for Data Scientists{" "}
            </h1>
          </a>
          <h2 className="text-indigo-600 font-semibold text-lg px-2 py-0">
            CHI '24 Best Paper Award
          </h2>
          <h2 className="text-gray-500 text-lg px-2 pt-0">
            Galen Harrison, Kevin Bryson, Ahmad Emmanuel Balla Bamba, Luca
            Dovichi, Aleksander Herrmann Binion, Arthur Borem, Blase Ur
          </h2>
        </div>
        <div className="pb-2">
          <a
            href="https://www.usenix.org/system/files/sec24fall-prepub-1238-borem.pdf"
            target="_blank"
          >
            <h1 className="text-gray-700 text-xl font-bold p-2 pb-0">
              Data Subjects’ Reactions to Exercising Their Right of Access
            </h1>
          </a>
          <h2 className="text-gray-500 text-lg px-2 pt-0">
            Arthur Borem, Elleen Pan, Olufunmilola Obielodan, Aurelie
            Roubinowitz, Luca Dovichi, Michelle L Mazurek, Blase Ur
          </h2>
        </div>
      </div>
    );

  if (currentTab == "Sample")
    return (
      <div className="flex-grow px-4 py-5 sm:p-6 overflow-y-scroll">
        <h1 className="text-gray-700 text-2xl font-bold p-2">Work Sample</h1>
        <h2 className="text-gray-500 text-xl font-bold p-2">
          In the fall, I built a distributed LLM training system for my research
          team. I was the sole developer on the system, but iterated with my
          manager and PI to ensure compliance with security and privacy
          standards. At the end of implementation, I consolidated some notes on
          the system into a single document to demonstrate how the system
          changed over time.
        </h2>
        <a href={recruitmentResources.sample} target="_blank">
          <div className="inline-flex gap-2 my-2 mx-2 p-3 bg-blue-600 text-white font-semibold items-center hover:ring ring-blue-600  transition shadow border-gray-300 border rounded-md">
            Access Document
            <ArrowRightIcon className="h-6 w-6" />
          </div>
        </a>
      </div>
    );

  return (
    <div className="flex-grow px-4 py-5 sm:p-6 overflow-y-scroll">
      <h1 className="text-gray-700 text-2xl font-bold p-2">Projects</h1>
      <h1 className="text-gray-700 text-xl font-bold p-2 pb-1">Whenfin</h1>
      <h2 className="text-gray-600 text-lg font-semibold p-2 pt-1 pb-2">
        My current project, Whenfin, is a platform that allows Python scripts to
        send notifications to iOS devices. It includes a{" "}
        <a
          href="https://pypi.org/project/whenfin/"
          target="_blank"
          className="text-blue-600"
        >
          Python module
        </a>{" "}
        which connects to an Express API, a mobile app, and a{" "}
        <a className="text-blue-600" href="https://whenfin.com" target="_blank">
          webpage
        </a>
        . I've recently improved infrastructure uptime, so the app is available
        for testing on{" "}
        <a
          className="text-blue-600"
          href="https://apps.apple.com/us/app/whenfin/id6504001973"
          target="_blank"
        >
          the App Store
        </a>
        . Hoping to port to Android soon.
      </h2>
      <a href="https://whenfin.com" target="_blank">
        <div className="bg-gray-200 border border-gray-400 rounded-lg shadow-md p-4 inline-flex gap-3 items-center hover:cursor-pointer transition mb-5">
          <div className="relative">
            <div className="absolute bg-green-500 animate-ping h-3 w-3 rounded-full opacity-75" />
            <div className="bg-green-500 h-3 w-3 rounded-full" />
          </div>
          <span className="font-semibold text-gray-700 text-lg">Whenfin</span>
          <ArrowRightIcon className="h-6 w-6 text-gray-700" />
        </div>
      </a>
      <h1 className="text-gray-700 text-xl font-bold p-2 pb-1">Loopster</h1>
      <h2 className="text-gray-600 text-lg font-semibold p-2 pt-1 pb-2">
        Loopster was my pet project last summer; the open-source web application
        allows for users to process their Spotify playing history in order to
        retroactively make playlists that contain songs they have enjoyed
        listening to in series. The code is published on{" "}
        <a
          href="https://github.com/Luca-W-D/loopster"
          target="_blank"
          className="text-blue-600"
        >
          Github
        </a>{" "}
        & is hosted on Netlify.
      </h2>
      <a href="https://loopster.l-uca.com" target="_blank">
        <div className="bg-gray-200 border border-gray-400 rounded-lg shadow-md p-4 inline-flex gap-4 items-center hover:cursor-pointer transition mb-5">
          <div className="relative">
            <div className="absolute bg-green-500 animate-ping h-3 w-3 rounded-full opacity-75" />
            <div className="bg-green-500 h-3 w-3 rounded-full" />
          </div>
          <span className="font-semibold text-gray-700 text-lg">Loopster</span>
          <ArrowRightIcon className="h-6 w-6 text-gray-700" />
        </div>
      </a>
      <h1 className="text-gray-700 text-xl font-bold p-2 pb-1">
        Past projects
      </h1>
      <h2 className="text-gray-600 text-lg font-semibold p-2 pt-1 pb-4">
        I've worked on dozens of personal projects since starting high school,
        most of which have too many security vulnerabilities to host now...
      </h2>
      <h2 className="text-gray-600 text-lg font-semibold p-2 pt-1 pb-2">
        My largest published project is{" "}
        <a
          href="https://github.com/Luca-W-D/Blackbox/"
          target="_blank"
          className="text-blue-600"
        >
          Blackbox
        </a>
        , a combined full-stack website and botnet used to scrape market data
        from a game called Starscape. With permission from the game owner, the
        service collected & served gigabytes of market data live to paying
        customers.
      </h2>
      <div className="bg-gray-50 border border-gray-400 rounded-lg shadow-md p-4 inline-flex gap-4 items-center hover:cursor-not-allowed transition mb-5">
        <div className="relative">
          <div className="bg-red-500 h-3 w-3 rounded-full" />
        </div>
        <span className="font-semibold text-gray-400 text-lg">Blackbox</span>
      </div>
      <h2 className="text-gray-600 text-lg font-semibold p-2 pt-1 pb-4">
        Check out my{" "}
        <a
          href="https://github.com/Luca-W-D/"
          target="_blank"
          className="text-blue-600"
        >
          Github
        </a>
        , where I occassionally upload some prior archived projects
      </h2>
    </div>
  );
}
